import { formattedCurrency } from "../../../utils/currency";
import { formattedDate } from "../../../utils/dateTime";
import { TableLinkCell } from "./TableLinkCell";

type TableCellProps<T> = {
  value: T[keyof T];
  formatting?: "date" | "currency" | "link";
};

const TableCell = <T,>({ value, formatting }: TableCellProps<T>) => {
  const renderValue = () => {
    if (value === undefined) {
      return "";
    }

    switch (formatting) {
      case "date":
        return formattedDate(value as number);
      case "currency":
        return formattedCurrency(value as number);
      case "link":
        return <TableLinkCell url={String(value)} />;
      default:
        return String(value);
    }
  };

  return <p>{renderValue()}</p>;
};

export default TableCell;
