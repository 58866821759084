import {
  CollectionReference,
  collection,
  doc,
  getDoc,
  onSnapshot,
  query,
  updateDoc,
  where,
  orderBy,
  getDocs,
} from "firebase/firestore";
import { Post } from "../types/post.type";
import { db } from "./firebase";

type GetAllSuscriptionParams = {
  onDataChange: (posts: Post[]) => void;
  status?: "pending" | "approved" | "rejected" | "blocked";
  userId?: string;
};

const getAllSuscription = ({ status, userId, onDataChange }: GetAllSuscriptionParams) => {
  let q = query(collection(db, "posts") as CollectionReference<Post>);

  if (status) {
    q = query(q, where("status", "==", status));
    q = query(q, orderBy("businessName", "asc"));
  }

  if (userId) {
    q = query(q, where("userId", "==", userId));
  }

  const unsubscribe = onSnapshot(q, (snapshot) => {
    const posts: Post[] = [];

    snapshot.forEach((doc) => {
      const postData = doc.data();
      posts.push(postData);
    });

    onDataChange(posts);
  });

  return unsubscribe;
};

const getById = async (categoryId: string) => {
  const docRef = doc(db, "posts", categoryId);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    return docSnap.data() as Post;
  } else {
    //throw new Error("post-not-found");
    return null;
  }
};

type PostUpdateParams = {
  status: string;
};

const update = (postId: string, params: PostUpdateParams) => {
  const postRef = doc(db, "posts", postId);

  return updateDoc(postRef, params);
};

const getRandomPosts = async (N: number, categoryId: string) => {
  const q = query(
    collection(db, "posts") as CollectionReference<Post>,
    where("status", "==", "approved"),
    where("isActive", "==", true),
    where("categoryId", "==", categoryId),
  );

  // Get all posts that match the criteria
  const snapshot = await getDocs(q);

  const allPosts: Post[] = [];

  snapshot.forEach((doc) => {
    const postData = doc.data();
    allPosts.push(postData);
  });

  // Shuffle the array and return N random posts
  const shuffledPosts = allPosts.sort(() => 0.5 - Math.random());
  const randomPosts = shuffledPosts.slice(0, N);

  return randomPosts;
};

export const PostService = {
  getAllSuscription,
  getById,
  update,
  getRandomPosts,
};
