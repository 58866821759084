import clsx from "clsx";
import React from "react";

interface CardProps {
  className?: string;
  title?: string;
  children: React.ReactNode;
}

const Card: React.FC<CardProps> = ({ className, title, children }) => {
  return (
    <div className={clsx("rounded border bg-slate-100 p-4", className)}>
      {title && <div className="mb-4 text-lg font-bold">{title}</div>}
      <div className="text-base">{children}</div>
    </div>
  );
};

export default Card;
