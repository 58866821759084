import { Profile } from "../types";
import { db } from "./firebase";
import { collection, getDocs } from "firebase/firestore";
import { ProfileService } from "./profile.service";

const COLLECTION = "preferredProviders";

const getAll = async (): Promise<Profile[]> => {
  const querySnapshot = await getDocs(collection(db, COLLECTION));
  const preferredProviders: Profile[] = [];

  const profilesPromises = querySnapshot.docs.map(async (doc) => {
    const profileId = doc.id;
    return await ProfileService.getById(profileId);
  });

  const profiles = await Promise.all(profilesPromises);
  preferredProviders.push(...profiles);

  return preferredProviders;
};

export const PreferredProvidersService = {
  getAll,
};
