import useFetchDocument from "../../../hooks/useFetchDocument";
import { Profile } from "../../../types";
import ProfileBasicInfo from "./BasicInfo";
import ProviderStatsInfo from "./ProviderStatsInfo";
import StatsInfo from "./StatsInfo";

export const ProfileInfo = ({
  className,
  profileId,
}: {
  className?: string;
  profileId: string;
}) => {
  const profileSnap = useFetchDocument<Profile>({
    collection: "profiles",
    id: profileId,
  });

  if (profileSnap.status === "loading") {
    return <div>Loading...</div>;
  }

  if (profileSnap.status === "error") {
    return <div>Error loading profile...</div>;
  }

  const profile = profileSnap.data;

  return (
    <div className={className}>
      <ProfileBasicInfo profile={profile} />
      <StatsInfo profile={profile} />
      <ProviderStatsInfo profile={profile} />
    </div>
  );
};
