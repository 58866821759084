import { useState } from "react";
import { PaymentLink } from "../../../interfaces/invoice.interface";
import { callFirebaseFunction } from "../../../services/firebase";

interface DeactivateButtonProps {
  paymentLink: PaymentLink;
  invoiceId: string;
  action: "deactivate" | "activate";
}

const DeactivateButton = ({ action, paymentLink, invoiceId }: DeactivateButtonProps) => {
  const [disabled, setDisabled] = useState(false);

  const handleDeactivate = async (action: "deactivate" | "activate") => {
    if (window.confirm(`Are you sure you want to ${action} this link?`)) {
      setDisabled(true);
      try {
        const result = await callFirebaseFunction<{ success: boolean; paymentLink: PaymentLink }>(
          "invoicesUpdatePaymentLink",
          {
            invoiceId: invoiceId,
            paymentLinkId: paymentLink.paymentLinkId,
            active: action === "deactivate" ? false : true,
          },
        );
        window.location.reload();
        console.log(result);
      } catch (error) {
        console.error(error);
      } finally {
        setDisabled(false);
      }

      //window.location.reload();
    }
  };

  return (
    <>
      <button
        onClick={() => handleDeactivate(action)}
        className={`btn-outline btn-sm btn ${action === "deactivate" ? "btn-warning" : "btn-success"}`}
        disabled={disabled}
      >
        {action === "deactivate" ? "Deactivate" : "Reactivate"}
      </button>
    </>
  );
};

export default DeactivateButton;
