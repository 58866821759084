import { useEffect, useState } from "react";
import type { ChatCounter } from "../../types/chatCounter.type";
import useFetchDocument from "../../hooks/useFetchDocument";
import { BiMessage, BiPhone } from "react-icons/bi";

export const ChatCommunicationMetrics = ({ chatId }: { chatId: string }) => {
  const [chatCounter, setChatCounter] = useState<ChatCounter | null>(null);

  const fetchChatCounter = useFetchDocument<ChatCounter>({
    collection: "chatCounters",
    id: chatId,
  });

  useEffect(() => {
    if (fetchChatCounter.data) {
      setChatCounter(fetchChatCounter.data);
    }
  }, [fetchChatCounter.data]);

  if (fetchChatCounter.error) return <div>{fetchChatCounter.error.message}</div>;

  if (!chatCounter) return <div></div>;

  return (
    <div className="flex flex-row gap-1 lg:flex-col">
      {(chatCounter.customerCallAttempts || chatCounter.customerSmsAttempts) && (
        <div className="badge-primary badge flex items-center gap-1">
          C
          {chatCounter.customerCallAttempts && (
            <div className="flex items-center">
              <BiPhone /> {chatCounter.customerCallAttempts}
            </div>
          )}
          {chatCounter.customerSmsAttempts && (
            <div className="flex items-center">
              <BiMessage /> {chatCounter.customerSmsAttempts}
            </div>
          )}
        </div>
      )}
      {(chatCounter.providerCallAttempts || chatCounter.providerSmsAttempts) && (
        <div className="badge-secondary badge">
          P
          {chatCounter.providerCallAttempts && (
            <div className="flex items-center">
              <BiPhone /> {chatCounter.providerCallAttempts}
            </div>
          )}
          {chatCounter.providerSmsAttempts && (
            <div className="flex items-center">
              <BiMessage /> {chatCounter.providerSmsAttempts}
            </div>
          )}
        </div>
      )}
    </div>
  );
};
