import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { ProfileService } from "../../services";
import { routeNames } from "../../routes";
import { BiArrowBack } from "react-icons/bi";
import { PageHeader } from "../../components/shared";
import ProviderView from "./profilesShowPage/ProviderVIew";
import CustomerView from "./profilesShowPage/CustomerView";
import PaymentsView from "./profilesShowPage/PaymentsView";
import FinancialDocumentsView from "./profilesShowPage/FinancialDocumentsView";
import { GrantConnectionsButton } from "../../components/profiles/GrantConnectionsButton";
import { ProfileInfo } from "./profilesShowPage/ProfileInfo";

const ProfileShowPage = () => {
  const { profileId } = useParams<{ profileId: string }>();
  const [profile, setProfile] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [activeTab, setActiveTab] = useState<
    "customer" | "provider" | "payments" | "financialDocuments"
  >("customer");

  useEffect(() => {
    const fetchProfile = async (profileId: string) => {
      try {
        const profile = await ProfileService.getById(profileId);
        setProfile(profile);
      } catch (err) {
        setError("Error fetching profile data");
      } finally {
        setLoading(false);
      }
    };

    if (profileId) {
      fetchProfile(profileId);
    }
  }, [profileId]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <>
      {profile && (
        <div className="flex flex-col gap-8">
          <div className="flex items-end justify-between">
            <div className="flex items-end">
              <Link to={routeNames["profiles.index"]} onClick={() => window.history.back()}>
                <BiArrowBack size={30} className="mr-2" />
              </Link>
              <PageHeader>{`${profile.name} profile`}</PageHeader>
            </div>
            <div>
              <GrantConnectionsButton userId={profile.id} className="btn-sm" />
            </div>
          </div>

          <ProfileInfo profileId={profileId!} className="flex flex-col gap-4 md:flex-row" />

          <div className="flex space-x-4">
            <button
              className={`py-2 px-4 ${
                activeTab === "customer" ? "border-b-2 border-blue-500" : ""
              }`}
              onClick={() => setActiveTab("customer")}
            >
              As customer
            </button>
            <button
              className={`py-2 px-4 ${
                activeTab === "provider" ? "border-b-2 border-blue-500" : ""
              }`}
              onClick={() => setActiveTab("provider")}
            >
              As provider
            </button>
            <button
              className={`py-2 px-4 ${
                activeTab === "payments" ? "border-b-2 border-blue-500" : ""
              }`}
              onClick={() => setActiveTab("payments")}
            >
              Payments
            </button>
            <button
              className={`py-2 px-4 ${activeTab === "financialDocuments" ? "border-b-2 border-blue-500" : ""}`}
              onClick={() => setActiveTab("financialDocuments")}
            >
              Invoices
            </button>
          </div>

          {activeTab === "customer" && <CustomerView profileId={profileId!} />}
          {activeTab === "provider" && <ProviderView profileId={profileId!} />}
          {activeTab === "payments" && <PaymentsView profileId={profileId!} />}
          {activeTab === "financialDocuments" && <FinancialDocumentsView profileId={profileId!} />}
        </div>
      )}
    </>
  );
};

export default ProfileShowPage;
