import { useState } from "react";
import { InvoicesList } from "../../components/lists/InvoicesList";
import InvoicesPreferredIndexPage from "./preferred/InvoicesIndexPage";

const InvoicesIndexPage = () => {
  const [activeTab, setActiveTab] = useState<"preferred" | "all">("preferred");

  return (
    <>
      <div className="flex flex-col gap-8">
        <div className="flex space-x-4">
          <button
            className={`py-2 px-4 ${activeTab === "preferred" ? "border-b-2 border-blue-500" : ""}`}
            onClick={() => setActiveTab("preferred")}
          >
            Preferred providers
          </button>
          <button
            className={`py-2 px-4 ${activeTab === "all" ? "border-b-2 border-blue-500" : ""}`}
            onClick={() => setActiveTab("all")}
          >
            All providers
          </button>
        </div>
        {activeTab === "preferred" && <InvoicesPreferredIndexPage />}
        {activeTab === "all" && <InvoicesList />}
      </div>
    </>
  );
};

export default InvoicesIndexPage;
