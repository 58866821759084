import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { routeNames } from "../../routes";
import { PostStatus } from "../../types";

import Table, { Column } from "../shared/Table/Table";
import { searchPost } from "../../services/typesense/post.service";
import { TablePagination } from "../shared/Table/TablePagination";

type PostListProps = {
  status?: PostStatus;
  userId?: string;
};

interface PostList {
  id: string;
  businessName: string;
  categoryName: string;
  createdAt: number;
  lastInteractionAt?: number;
}

const columns: Column<PostList>[] = [
  { header: "ID", accessor: "id", hidden: true },
  { header: "Name", accessor: "businessName" },
  { header: "Category", accessor: "categoryName" },
  { header: "Created at", accessor: "createdAt", formatting: "date" },
  { header: "Last interaction at", accessor: "lastInteractionAt", formatting: "date" },
];

const ITEMS_PER_PAGE = 20;

export const PostsList = ({ status, userId }: PostListProps) => {
  const [posts, setPosts] = useState<PostList[]>();
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [recordsCount, setRecordsCount] = useState(0);

  const [searchTerm, setSearchTerm] = useState<string>("");
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState(searchTerm);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm);
    }, 500);

    return () => {
      clearTimeout(handler);
    };
  }, [searchTerm]);

  useEffect(() => {
    const fetchPosts = async () => {
      setLoading(true);
      try {
        const postResult = await searchPost({
          perPage: ITEMS_PER_PAGE,
          page: page + 1,
          q: debouncedSearchTerm,
          status,
          userId,
        });
        setPosts(postResult.hits);
        setRecordsCount(postResult.found);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    fetchPosts();
  }, [debouncedSearchTerm, status, userId, page]);

  const actionCell = (row: PostList) => {
    return (
      <Link to={routeNames["posts.show"](row.id)}>
        <button className="btn-primary btn-sm btn mr-2">Details</button>
      </Link>
    );
  };

  return (
    <div className="flex flex-col gap-8">
      <input
        className="input w-full"
        placeholder="Search by business name or category..."
        value={searchTerm}
        onChange={(e) => {
          console.log(e.target.value);
          setSearchTerm(e.target.value);
        }}
      />
      {loading && <p>Loading...</p>}
      {!loading && posts && (
        <>
          <Table columns={columns} data={posts} actionCell={actionCell} />
          <TablePagination
            currentPage={page}
            itemsPerPage={ITEMS_PER_PAGE}
            recordsCount={recordsCount}
            onPageChange={setPage}
          />
        </>
      )}
    </div>
  );
};
