import { FormEvent, useState } from "react";
import { BiLoader } from "react-icons/bi";
import { toast } from "react-toastify";
import { callFirebaseFunction } from "../../services/firebase";
import { FirebaseError } from "firebase/app";
import { ErrorAlert } from "../shared";

export const GrantConnectionsForm = ({
  userId,
  onSuccess,
}: {
  userId: string;
  onSuccess: () => void;
}) => {
  const [credits, setCredits] = useState<number | null>();
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [notifyUser, setNotifyUser] = useState<boolean>(false);

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!credits) {
      alert("Please write something before submitting the form.");
      return;
    }

    try {
      setIsProcessing(true);
      await callFirebaseFunction<void>("adminGrantConnections", {
        credits,
        userId,
        notifyUser,
      });
      setCredits(null);
      toast.success("Connections granted successfully!");
      onSuccess();
    } catch (error) {
      if (error instanceof FirebaseError) {
        setError(error.message);
      } else {
        setError("An error occurred");
      }
    } finally {
      setIsProcessing(false);
    }
  };

  return (
    <form className="flex flex-col gap-4" onSubmit={handleSubmit}>
      <label className="font-medium" htmlFor="">
        How many connections?
      </label>
      <input
        className="textarea-bordered textarea w-full text-base-content"
        value={credits || ""}
        onChange={(e) => setCredits(parseInt(e.target.value))}
      />

      <div className="form-control">
        <label className="label cursor-pointer">
          <span className="label-text">
            <span className="text-base font-medium">Notify user? </span>
            (This will send a push notification to the user)
          </span>
          <input
            type="checkbox"
            className="toggle"
            checked={notifyUser}
            onChange={() => setNotifyUser(!notifyUser)}
          />
        </label>
      </div>

      {error && <ErrorAlert message={error} />}
      <div className="mt-2 flex gap-2">
        <button type="submit" className="btn-primary btn" disabled={isProcessing}>
          {isProcessing && <BiLoader className="mr-2 animate-spin" />}
          {isProcessing ? "Granting connections..." : "Grant Connections"}
        </button>
        <button type="button" className="btn-ghost btn" onClick={onSuccess}>
          Cancel
        </button>
      </div>
    </form>
  );
};
