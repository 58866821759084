import { TypeSenseProfile } from "../../types/typesense/profile.interface";
import Typesense, { TypesenseSearchResponse } from "../typesense.service";

const TYPESENSE_COLLECTION = `${process.env.REACT_APP_ENVIRONMENT}_profiles`;

export interface TypesenseSearchParams {
  page?: number;
  perPage?: number;
  q: string;
}

export const searchProfile = async ({
  page,
  perPage,
  q,
}: TypesenseSearchParams): Promise<TypesenseSearchResponse<TypeSenseProfile>> => {
  const queryBy = "name,email,phoneNumber,lastName";
  const infix = "off,off,fallback,off";
  const sortBy = "name:asc";
  return Typesense.search<TypeSenseProfile>(TYPESENSE_COLLECTION, {
    q,
    queryBy,
    infix,
    sortBy,
    page,
    perPage,
  });
};

export const getProfileById = async (id: string): Promise<TypeSenseProfile> => {
  return Typesense.get<TypeSenseProfile>(id, TYPESENSE_COLLECTION);
};
