type StatusBadgeProps = {
  status: string;
};

export const StatusBadge = ({ status }: StatusBadgeProps) => {
  const badgeColor = (status: string) => {
    switch (status) {
      case "active":
        return "badge-success";
      case "inactive":
        return "";
      case "pending":
        return "badge-warning";
      default:
        return "badge-primary";
    }
  };
  return <span className={`badge ${badgeColor(status)} ml-2 mb-1`}>{status}</span>;
};
