import { BiPlus } from "react-icons/bi";
import useToggle from "../../hooks/useToggle";
import { Modal } from "../shared/Modal/Modal";
import { GrantConnectionsForm } from "./GrantConnectionsForm";
import clsx from "clsx";

export const GrantConnectionsButton = ({
  className,
  userId,
}: {
  className?: string;
  userId: string;
}) => {
  const [showModal, setShowModal] = useToggle(false);

  const handleClick = () => {
    if (!showModal) setShowModal.set(true);
  };

  return (
    <>
      <button className={clsx("btn", className)} onClick={handleClick}>
        <BiPlus />
        Grant Connections
      </button>

      <Modal
        show={showModal}
        onCloseModal={() => setShowModal.set(false)}
        title="Grant Connections"
      >
        <div className="p-4 text-base-content">
          <GrantConnectionsForm userId={userId} onSuccess={() => setShowModal.set(false)} />
        </div>
      </Modal>
    </>
  );
};
