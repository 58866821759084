import Card from "../../../components/shared/Card";
import Title from "../../../components/shared/Title";
import { Profile } from "../../../types";
import ProfileLocationInfo from "./LocationInfo";

const ProfileBasicInfo = ({ profile }: { profile: Profile }) => {
  return (
    <Card className="flex-1">
      <Title>Basic info</Title>
      <div className="mt-6 flex flex-col gap-4">
        <p className="flex justify-between gap-2">
          <span>ID</span>
          <strong className="text-sm md:text-base">{profile.id}</strong>
        </p>
        <p className="flex justify-between gap-2">
          <span>Full name</span>
          <strong className="text-sm md:text-base">
            {profile.name} {profile.lastName}
          </strong>
        </p>
        <p className="flex items-center justify-between gap-2">
          <span>Email</span>
          <strong className="text-sm md:text-base">{profile.email}</strong>
        </p>
        <p className="flex justify-between gap-2">
          <span>Phone Number</span>
          <strong className="text-sm md:text-base">{profile.phoneNumber}</strong>
        </p>
        <p className="flex justify-between gap-2">
          <span>App language</span>
          <strong>{profile.locale}</strong>
        </p>
        <ProfileLocationInfo profileId={profile.id} />
      </div>
    </Card>
  );
};

export default ProfileBasicInfo;
