import { useEffect, useState } from "react";
import { TablePagination } from "../shared/Table/TablePagination";
import { Link } from "react-router-dom";
import { routeNames } from "../../routes";
import { getProfileById, searchProfile } from "../../services/typesense/profile.service";
import Table, { Column } from "../shared/Table/Table";

const ITEMS_PER_PAGE = 10;
const FIREBASE_ID_LENGTH = 28;
interface ProfileList {
  id: string;
  fullName: string;
  email?: string;
  phoneNumber?: string;
  lastName?: string;
}

const columns: Column<ProfileList>[] = [
  { header: "ID", accessor: "id" },
  { header: "Full Name", accessor: "fullName" },
  { header: "Email", accessor: "email" },
  { header: "Phone number", accessor: "phoneNumber" },
];

export const ProfilesList = () => {
  const [loading, setLoading] = useState(false);
  const [profiles, setProfiles] = useState<ProfileList[]>([]);
  const [page, setPage] = useState(0);
  const [recordsCount, setRecordsCount] = useState(0);

  const [searchTerm, setSearchTerm] = useState("");
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState(searchTerm);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm);
    }, 500);

    return () => {
      clearTimeout(handler);
    };
  }, [searchTerm]);

  useEffect(() => {
    const fetchProfiles = async () => {
      setLoading(true);
      try {
        if (debouncedSearchTerm.length === FIREBASE_ID_LENGTH) {
          const profile = await getProfileById(debouncedSearchTerm);
          setProfiles([
            {
              id: profile.id,
              fullName: `${profile.name} ${profile.lastName}`,
              email: profile.email,
              phoneNumber: profile.phoneNumber,
            },
          ]);
        } else {
          const profilesResult = await searchProfile({
            perPage: ITEMS_PER_PAGE,
            page: page + 1,
            q: debouncedSearchTerm,
          });

          setProfiles(
            profilesResult.hits.map((profile) => ({
              id: profile.id,
              fullName: `${profile.name} ${profile.lastName}`,
              email: profile.email,
              phoneNumber: profile.phoneNumber,
            })),
          );
          setRecordsCount(profilesResult.found);
        }
      } catch (error) {
        console.error("Failed to fetch profiles", error);
      } finally {
        setLoading(false);
      }
    };

    fetchProfiles();
  }, [debouncedSearchTerm, page]);

  const actionCell = (row: ProfileList) => {
    return (
      <Link to={routeNames["profiles.show"](row.id)}>
        <button className="btn-primary btn-sm btn mr-2">Details</button>
      </Link>
    );
  };

  return (
    <div className="flex flex-col gap-8">
      <input
        className="input w-full"
        placeholder="Search by email, phone number, or ID..."
        value={searchTerm}
        onChange={(e) => {
          console.log(e.target.value);
          setSearchTerm(e.target.value);
        }}
      />
      {loading && <p>Loading...</p>}
      {!loading && profiles && (
        <>
          <Table columns={columns} data={profiles} actionCell={actionCell} />
          <TablePagination
            currentPage={page}
            itemsPerPage={ITEMS_PER_PAGE}
            recordsCount={recordsCount}
            onPageChange={setPage}
          />
        </>
      )}
    </div>
  );
};
