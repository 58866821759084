import clsx from "clsx";
import { BiError } from "react-icons/bi";

type ErrorAlertProps = {
  className?: string;
  message: string;
};

export const ErrorAlert = ({ className, message }: ErrorAlertProps) => {
  return (
    <div className={clsx("alert alert-error shadow-lg", className)}>
      <div>
        <BiError />
        <span>{message}</span>
      </div>
    </div>
  );
};
