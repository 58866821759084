import { TypeSensePost } from "../../types/typesense/post.interface";
import Typesense, { TypesenseSearchResponse } from "../typesense.service";

const TYPESENSE_COLLECTION = `${process.env.REACT_APP_ENVIRONMENT}_posts`;

interface TypesenseSearchParams {
  page?: number;
  perPage?: number;
  q: string;
  status?: string;
  userId?: string;
}

export const searchPost = async ({
  page,
  perPage,
  q: searchTerm,
  status,
  userId,
}: TypesenseSearchParams): Promise<TypesenseSearchResponse<TypeSensePost>> => {
  const queryBy = "businessName,categoryName,fullAddress";

  let filterBy = null;
  if (status) filterBy = `status:${status}`;
  if (userId) filterBy = `userId:${userId}`;

  const sortBy = "createdAt:desc";

  return Typesense.search<TypeSensePost>(TYPESENSE_COLLECTION, {
    page,
    perPage,
    q: searchTerm,
    queryBy,
    sortBy,
    ...(filterBy && { filterBy }),
  });
};
