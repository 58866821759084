import { useEffect, useState } from "react";
import { PreferredProvidersService } from "../../../services/preferredProviders.service";
import { Profile } from "../../../types";
import { InvoicesList } from "../../../components/lists/InvoicesList";

const InvoicesPreferredIndexPage = () => {
  const [preferredProfiles, setPreferredProfiles] = useState<Profile[]>([]);
  const [loading, setLoading] = useState(false);
  const [selectedProfileId, setSelectedProfileId] = useState<string | null>(null);

  const fetchPreferredProfiles = async () => {
    setLoading(true);
    try {
      const profiles = await PreferredProvidersService.getAll();
      setPreferredProfiles(profiles);
    } catch (error) {
      console.error("Failed to fetch preferred profiles", error);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchPreferredProfiles();
  }, []);

  return (
    <div>
      {loading ? (
        <p>Cargando...</p>
      ) : preferredProfiles.length > 0 ? (
        preferredProfiles.map((profile, index) => {
          return (
            <div
              tabIndex={index}
              key={profile.id}
              className="collapse-arrow collapse border border-base-300 bg-base-200"
            >
              <input type="checkbox" onClick={() => setSelectedProfileId(profile.id)} />
              <div className="collapse-title text-xl font-medium">
                <b>
                  {profile.name} {profile.lastName}
                </b>{" "}
                <small>{profile.email}</small>
              </div>
              <div className="collapse-content">
                {selectedProfileId === profile.id && <InvoicesList userId={profile.id} />}
              </div>
            </div>
          );
        })
      ) : (
        <p>No profiles found</p>
      )}
    </div>
  );
};

export default InvoicesPreferredIndexPage;
