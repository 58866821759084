import React from "react";
import TableCell from "./TableCell";
import { BiInfoCircle } from "react-icons/bi";

export type Column<T> = {
  header: string;
  accessor: keyof T;
  formatting?: "date" | "currency" | "link";
  hidden?: boolean;
};

type TableProps<T> = {
  columns: Column<T>[];
  data: T[];
  actionCell?: (row: T) => React.ReactNode;
};

const Table = <T,>({ columns, data, actionCell }: TableProps<T>) => {
  return (
    <div className="overflow-x-auto">
      <table className="hidden min-w-full bg-white md:table">
        <thead>
          <tr className="border-b py-2 px-4">
            {columns.map((column) =>
              !column.hidden ? <th key={String(column.accessor)}>{column.header}</th> : null,
            )}
            {actionCell && <th>Actions</th>}
          </tr>
        </thead>
        <tbody>
          {data.length === 0 && (
            <tr>
              <td colSpan={columns.length + (actionCell ? 1 : 0)} className="py-4 text-center">
                <p className="flex items-center justify-center gap-2">
                  <BiInfoCircle />
                  No data available
                </p>
              </td>
            </tr>
          )}
          {data.map((row, rowIndex) => (
            <tr key={rowIndex}>
              {columns.map((column) =>
                column.hidden ? null : (
                  <td key={String(column.accessor)}>
                    <TableCell value={row[column.accessor]} formatting={column.formatting} />
                  </td>
                ),
              )}
              {actionCell && <td>{actionCell(row)}</td>}
            </tr>
          ))}
        </tbody>
      </table>
      <div className="md:hidden">
        {data.map((row, rowIndex) => (
          <div key={rowIndex} className="mb-4 rounded-lg bg-white p-4 shadow-md">
            {columns.map((column) => (
              <div key={String(column.accessor)} className="mb-2">
                <strong>{column.header}:</strong>{" "}
                <TableCell value={row[column.accessor]} formatting={column.formatting} />
              </div>
            ))}
            {actionCell && <div>{actionCell(row)}</div>}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Table;
