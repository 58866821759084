import useFetchDocument from "../../../hooks/useFetchDocument";
import { Knock } from "../../../types";

type Props = {
  knockId: string;
};

export const KnockStatusChip = ({ knockId }: Props) => {
  const knockSnap = useFetchDocument<Knock>({
    collection: "knocks",
    id: knockId,
  });

  if (["loading", "error"].includes(knockSnap.status)) {
    return <></>;
  }

  const knock = knockSnap.data;
  const statusBadgeClass = (knock: Knock) => {
    switch (knock.status) {
      case "searching":
        return "badge-info";
      case "active":
        return "badge-success";
      case "expired":
        return "badge-error";
      default:
        return "";
    }
  };

  return <span className={`badge ${statusBadgeClass(knock)}`}>{knock.status}</span>;
};
