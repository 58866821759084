import axios from "axios";
import { DEFAULT_ITEMS_PER_PAGE } from "../constants";

interface TypesenseSearchParams {
  filterBy?: string;
  infix?: string;
  page?: number;
  perPage?: number;
  q: string;
  queryBy: string;
  sortBy?: string;
}

export interface TypesenseSearchResponse<T> {
  hits: T[];
  outOf: number;
  found: number;
  page: number;
}

export default class Typesense {
  static headers = {
    "X-TYPESENSE-API-KEY": process.env.REACT_APP_TYPESENSE_API_KEY,
  };

  static search = async <T>(
    collection: string,
    { q, queryBy, sortBy, infix, filterBy, perPage, page }: TypesenseSearchParams,
  ): Promise<TypesenseSearchResponse<T>> => {
    const query = `q=${q ? q : "*"}`;
    const queryByParam = queryBy ? `&query_by=${queryBy}` : "";
    const sortByParam = sortBy ? `&sort_by=${sortBy}` : "";
    const infixParam = infix ? `&infix=${infix}` : "";
    const filterByParam = filterBy ? `&filter_by=${filterBy}` : "";
    const perPageParam = `&per_page=${perPage ? perPage : DEFAULT_ITEMS_PER_PAGE}`;
    const pageParam = `&page=${page ? page : 1}`;

    const response = await axios.get(
      `${process.env.REACT_APP_TYPESENSE_URL}/collections/${collection}/documents/search?${query}${queryByParam}${sortByParam}${infixParam}${filterByParam}${perPageParam}${pageParam}`,
      {
        headers: this.headers,
      },
    );

    const { data } = response;

    const results = data.hits.map((hit: any) => {
      const document = hit.document;
      document.userId = document.id;

      return document as Partial<T>;
    });

    return {
      hits: results,
      outOf: data.out_of,
      found: data.found,
      page: data.page,
    };
  };

  static get = async <T>(id: string, collection: string): Promise<T> => {
    const result = await axios.get(
      `${process.env.REACT_APP_TYPESENSE_URL}/collections/${collection}/documents/${id}`,
      {
        headers: this.headers,
      },
    );

    return result.data as T;
  };
}
