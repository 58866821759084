import { useEffect, useState } from "react";
import { getLocationProfile } from "../../../services/locationProfile.service";
import { LocationProfile } from "../../../types/location.interface";

const ProfileLocationInfo = ({ profileId }: { profileId: string }) => {
  const [loading, setLoading] = useState(true);
  const [location, setLocation] = useState<LocationProfile | null>(null);
  useEffect(() => {
    setLoading(true);
    const fetchLocation = async () => {
      const locationData = await getLocationProfile(profileId);
      console.log(locationData);
      setLocation(locationData);
    };

    fetchLocation();
    setLoading(false);
  }, [profileId]);

  if (loading) {
    return <p>Loading...</p>;
  }

  return (
    <p className="flex items-center justify-between gap-4">
      <span>Location</span>
      {location ? (
        <strong className="text-right text-sm md:text-base">
          {location.addressMainText} {location.addressSecondaryText}
        </strong>
      ) : (
        "(no location)"
      )}
    </p>
  );
};

export default ProfileLocationInfo;
